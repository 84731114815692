<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${pos === 0 ? $t('lbl.addTarifa') : $t('lbl.deleteTarifa')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-update' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="pos === 0"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyItems"
                @click="save()"
              >
                <span>{{ $t('btn.add') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="pos === 1"
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="verifyDelete"
                @click="isDialogVisibleDelete = true"
              >
                <span>{{ $t('btn.delete') }}</span>
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row v-if="isLoading">
              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-spacer></v-spacer>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>

            <template v-else>
              <v-row>
                <v-col
                  v-if="model.proveedor_id"
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <p class="mt-3">
                    <strong class="ml-10">{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tabs
                    v-model="pos"
                    icons-and-text
                  >
                    <v-tab>
                      <v-icon>{{ icons.mdiPlus }}</v-icon>
                    </v-tab>
                    <v-tab>
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="pos">
                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-if="!loadingCategories"
                            v-model="tarifa.name"
                            hide-details
                            :label="$t('lbl.name')"
                            class="my-input mr-2"
                            outlined
                            dense
                          ></v-text-field>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-select
                            v-if="!loadingCategories"
                            v-model="tarifa.category_id"
                            :items="categoriesTarifarios"
                            :label="$t('menu.categoriesTarifarios')"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="id"
                            @change="changeCategory"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('menu.categoriesTarifarios') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-select>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item>
                      <v-row class="mt-5">
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-select
                            v-if="!isLoading"
                            v-model="tarifa.tarifa_code"
                            :items="tarifas"
                            :label="$t('lbl.tarifa')"
                            class="my-input mr-2"
                            outlined
                            dense
                            hide-details
                            item-text="name"
                            item-value="code"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-title>
                                  {{ $t('lbl.tarifa') }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }} (<b>{{ item.code }}</b>)</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                          <v-skeleton-loader
                            v-else
                            class="mx-auto"
                            type="card-heading"
                          ></v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </template>
          </v-form>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <template v-if="!isLoadingHotels">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-uppercase"
                          width="5%"
                          rowspan="2"
                        >
                          <v-checkbox
                            v-model="checkAll"
                            hide-details
                            class="my-0"
                            @change="changeCheckAll"
                          ></v-checkbox>
                        </th>
                        <th
                          class="text-uppercase"
                          width="40%"
                          rowspan="2"
                        >
                          {{ $t('lbl.hotel') }}
                        </th>
                        <th
                          class="text-uppercase"
                          rowspan="2"
                        >
                          {{ $t('lbl.destino') }}
                        </th>
                        <th
                          class="text-uppercase text-center"
                          colspan="3"
                        >
                          {{ $t('lbl.tarifa') }}
                        </th>
                      </tr>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.name') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.code') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.name') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <fragment
                        v-for="(iten, indP) in hotels"
                        :key="indP"
                      >
                        <tr v-if="iten.id === iten.id">
                          <td>
                            <v-checkbox
                              v-model="iten.check"
                              hide-details
                              class="my-0"
                              @change="changeCheckItem"
                            ></v-checkbox>
                          </td>
                          <td>
                            <NameHotelStar :hotel="iten" />
                          </td>
                          <td>
                            {{ `${iten.city ? `${iten.city}, ` : ''}` }}
                            <span v-if="iten.country">{{ iten.country }}</span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      {{ tarifa.name }}
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      {{ tarifa.code }}
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                          <td>
                            <span
                              v-for="(season, ind) in seasons"
                              :key="ind"
                            >
                              <span v-if="season.hotels.includes(iten.id)">
                                <span v-if="season.seasons">
                                  <span v-if="season.seasons.tarifas">
                                    <span
                                      v-for="(tarifa, indT) in season.seasons.tarifas"
                                      :key="indT"
                                    >
                                      <i>{{ tarifa.category_slug }}</i>
                                      <br />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </td>
                        </tr>
                      </fragment>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
              <v-skeleton-loader
                v-else
                class="mx-auto"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>

    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          ¿Está seguro que desea eliminar la tarifa seleccionada?
          <br />
          <br />
          Esta acción es irreversible y dejaría a los hoteles sin precios de esta tarifa.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline, mdiUpdate,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import NameHotelStar from '@/views/products/hotels/utils/NameHotelStar.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    AppCardCode,
    NameHotelStar,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      pos: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
      },
      tarifa: {
        code: uuidv4().toUpperCase().substring(28),
        name: '',
        category_id: 0,
        category_slug: '',
        tarifa_code: 0,
      },
      loading: false,
      loadingCategories: true,
      categoriesTarifarios: [],
      hotels: [],
      hotelsNomAll: [],
      isLoadingHotels: true,
      hotels_pactados: [],
      checkAll: true,
      model: null,
      seasons: [],
      tarifas: [],
      tarifasCodes: [],
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
    verifyItems() {
      let result = false
      if (!this.tarifa.name || !this.tarifa.category_id) {
        result = true
      }
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
    verifyDelete() {
      let result = false
      if (!this.tarifa.tarifa_code) {
        result = true
      }
      let cantCheck = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cantCheck += 1
        }
      }
      if (cantCheck === 0) {
        result = true
      }

      return result
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
  },

  created() {
    this.getItem()
    this.getCategoriesTarifarios()
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.seasons = this.model.seasons
            this.hotels_pactados = this.model.hotels_pactados

            // LLENAR LAS TARIFAS
            this.seasons.forEach(elementSea => {
              if (elementSea.seasons) {
                if (elementSea.seasons.tarifas) {
                  elementSea.seasons.tarifas.forEach(elementTar => {
                    if (!this.tarifasCodes.includes(elementTar.code)) {
                      this.tarifasCodes.push(elementTar.code)
                      this.tarifas.push({
                        name: elementTar.name,
                        code: elementTar.code,
                      })
                    }
                  })
                }
              }
            })
          })
          .finally(() => {
            this.isLoading = false
            this.getHoteles()
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getCategoriesTarifarios() {
      this.axios
        .get(
          'nom_categories_tarifarios_hotels?per_page=10000',
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.categoriesTarifarios = res.data.data
          }
        })
        .finally(() => {
          this.loadingCategories = false
        })
    },
    changeCategory() {
      if (this.tarifa.category_id) {
        this.tarifa.category_slug = this.categoriesTarifarios.filter(e => e.id === this.tarifa.category_id)[0].slug
      }
    },
    getHoteles() {
      const json = {
        page: 1,
        per_page: 100000,
        fields: [
          'id',
          'name',
          'slug',
          'operador_id',
          'marca_asociada_id',
          'categoria_id',
          'tipo_destino_id',
          'sugerido',
          'number_sugerido',
          'checkIn',
          'checkOut',
          'direccion',
          'publicado',
          'cant_departament',
        ],
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelsNomAll = res.data.data
            this.hotelsNomAll.forEach(element => {
              if (this.hotels_pactados.includes(element.id)) {
                this.hotels.push({
                  ...element,
                  check: true,
                })
              }
            })
          }
        })
        .finally(() => {
          this.isLoadingHotels = false
        })
    },
    changeCheckAll() {
      if (this.checkAll) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = true
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.hotels.length; index++) {
          this.hotels[index].check = false
        }
      }
    },
    changeCheckItem() {
      const cantH = this.hotels.length
      let cont = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.hotels.length; index++) {
        if (this.hotels[index].check) {
          cont += 1
        }
      }
      if (cont === cantH) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
    save() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.id)
        }
      })
      this.tarifa.hotels = hot
      this.tarifa.contrate_id = sessionStorage.getItem('hotels-contrate-id')

      this.loading = true
      this.axios
        .post('contrate_hotels/agregar-tarifa-global', this.tarifa, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            if (response.data.data.hotelsRepetido !== '') {
              this.$toast.info(this.$t('msg.hotelsWithTarifa', { hotels: response.data.data.hotelsRepetido }))
            }
            this.$toast.success(this.$t('msg.infoSuccess'))

            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    confirmDelete() {
      const hot = []
      this.hotels.forEach(element => {
        if (element.check) {
          hot.push(element.id)
        }
      })
      this.tarifa.hotels = hot
      this.tarifa.contrate_id = sessionStorage.getItem('hotels-contrate-id')

      this.loading = true
      this.axios
        .post('contrate_hotels/delete-tarifa-global', this.tarifa, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))

            this.$router.push({ name: 'hotels-contrate-update' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isDialogVisibleDelete = false
          this.loading = false
        })
    },
  },
}
</script>
